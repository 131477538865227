import {webAPIUrl} from './AppSettings';

export interface HttpRequest<REQB> {
    path: string;
    method?: string;
    body?: REQB;
    accessToken?: string;
    }
    export interface HttpResponse<RESB> extends Response {
    parsedBody?: RESB;
    }

    export const http = <REQB, RESB>(config: HttpRequest<REQB>,): Promise<HttpResponse<RESB>> => {
        return new Promise((resolve, reject) => {
        const request = new Request(`${webAPIUrl}${config.path}`, {
            method: config.method || 'get',
            headers: {
              'Content-Type': 'application/json',
            },
            body: config.body ? JSON.stringify(config.body) : undefined,
          });
        let response: HttpResponse<RESB>;
        fetch(request)
        .then(res => {
            response = res;
            return res.json();
            })
            .then(body => {
            if (response.ok) {
            response.parsedBody = body;
            resolve(response);
            } else {
            reject(response);
            }
            })
            .catch(err => {
            console.error(err);
            reject(err);
            });
        // TODO - resolve the promise with the parsed body if a  successful request
       
        // TODO - reject the promise if the request is unsuccessful
        });
        };