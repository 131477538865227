import {http} from './http';

export interface Balda {
    BALDA_ID: number;
    CADAA_ID: number;
    BALDA_NAME: string;
    
    }

    export const GetBaldaList=async ():Promise<Balda[]>=>
    {
        let myBaldaList:Balda[] =[];
        const url=`http://localhost:35495/api/Baldas/Get_Baldas`;
        const options=
        {
            method:"GET",
            headers:{
                Accept:"application/json",
                "Content-Type":"application/json;charset=UTF-8",
            },
        };
        await fetch(url,options).
        then(response=>response.json())
        .then((data)=>{

            myBaldaList=data;

        }).catch(err => {
            console.error(err);
            });
        

             return myBaldaList;

    }

    export const GetBaldas = async (): Promise<Balda[]> => {
try {
const result = await http<undefined,Balda[]>({
path: '/Baldas/Get_Baldas',
});
if (result.parsedBody) {
   
return result.parsedBody; //.map(mapQuestionFromServer)
} else {
return [];
}
} catch (ex) {
console.error(ex);
return [];
}
};