
import {http} from './http';

export interface Person {
    PERSON_ID: Number;   
    
    ESM?: string;
    CHOHRA?: string;
    ESM_ABB?:String;
    ESM_ELOM?:String;
    MAZHAB_DESC?:string;
    BALDA_NAME?: string;
    KENYA?:string;
    GENDER_DESC?:string;
    BIRTH_DATE_TEXT?:string;
    SEJEL?:number;
    COUNTRY_NAME?:string;
    ADDRESS?:string;
    REMARKS?:String;
    PHONE_DESC?:string;
    }



    export interface Result_Get_Person_List
    {
        data: Person[],
        total:number,
    }

    
    export const GetPersons = async (BALDA_ID:number,ESM:string,CHOHRA:string,MAZHAB_ID:number,SEJEL:number,PAGE:number,PAGE_SIZE:number): Promise<Result_Get_Person_List | null> => {
try {
const result = await http<undefined,Result_Get_Person_List>({
path: `/Persons/Get_Persons/${BALDA_ID}/${ESM}/${CHOHRA}/${MAZHAB_ID}/${SEJEL}/${PAGE}/${PAGE_SIZE}`,
});
if (result.parsedBody) {
   
return result.parsedBody; 
} else {
return null;
}
} catch (ex) {
console.error(ex);
return null;
}
};