import {http} from './http';

export interface Mazhab {
    MAZHAB_ID: number;
    
    MAZHAB_DESC: string;
    
    }

    export const GetMazhabList=async ():Promise<Mazhab[]>=>
    {
        let myMazhabList:Mazhab[] =[];
        const url=`http://localhost:35495/api/Mazhabs/Get_Mazhabs`;
        const options=
        {
            method:"GET",
            headers:{
                Accept:"application/json",
                "Content-Type":"application/json;charset=UTF-8",
            },
        };
        await fetch(url,options).
        then(response=>response.json())
        .then((data)=>{

            myMazhabList=data;

        }).catch(err => {
            console.error(err);
            });
        

             return myMazhabList;

    }

    export const GetMazhabs = async (): Promise<Mazhab[]> => {
try {
const result = await http<undefined,Mazhab[]>({
path: '/Mazhabs/Get_Mazhabs',
});
if (result.parsedBody) {
   
return result.parsedBody; //.map(mapQuestionFromServer)
} else {
return [];
}
} catch (ex) {
console.error(ex);
return [];
}
};