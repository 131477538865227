import React from 'react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
/** @jsx jsx */ /** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';
import { fontFamily, fontSize, gray1, gray2, gray5 } from './Styles';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { Autocomplete, TextField,  Button,  FormControl,  Input,  InputLabel,
  Container,Grid} from '@mui/material';
import { DataGrid, GridCallbackDetails, GridPaginationModel, GridColDef, GridToolbar, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Balda, GetBaldas } from './Balda';
import { Mazhab, GetMazhabs } from './Mazhab';
import { Person, GetPersons, Result_Get_Person_List } from './Person';

const Columns:GridColDef[] = [
  
  { field: 'ESM', headerName: 'First Name' },
  { field: 'CHOHRA', headerName: 'Last Name',width:125 },
  {field:'ESM_ABB', headerName:'Father Name'},
  {field:'ESM_ELOM', headerName:'Mother Name',width:150},
  {field:'KENYA', headerName:'Kenya'},
  {field:'BALDA_NAME', headerName:'Balda'},
  {field:'MAZHAB_DESC', headerName:'mazhab'},
  {field:'GENDER_DESC', headerName:'gender'},
  {field:'BIRTH_DATE_TEXT', headerName:'birth date'},
  {field:'SEJEL', headerName:'sejel'},
  {field:'COUNTRY_NAME', headerName:'country'},
  {field:'PHONE_DESC', headerName:'phone'},
  {field:'ADDRESS', headerName:'Address'},
  {field:'REMARKS', headerName:'Remarks'},
  

];
export const Criteria: React.FC = () => {
  const [BaldaList, setBaldaList] = useState<Balda[]>([]);
  const [SelectedBalda, setSelectedBalda] = useState<Balda | null>(null);

  const [MazhabList, setMazhabList] = useState<Mazhab[]>([]);
  const [SelectedMazhab, setSelectedMazhab] = useState<Mazhab | null>(null);

  const [firstName, setFirstName] = useState<string>(' ');
  const [lastName, setLastName] = useState<string>(' ');

  const [BaldaID, setBaldaID] = useState<number | undefined>(0);
  const [MazhabID, setMazhabID] = useState<number | undefined>(0);
  const [Sejel, setSejel] = useState<number | undefined>(0);

  const [pageState,setPageState]=useState({    
    total:0,   
    

  })

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const [PersonList, setPersonList] = useState<Person[]>([]);

  const handleFirstNameInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e.currentTarget.value);
    setFirstName(e.currentTarget.value.replace(""," "));
  };

  const handleLastNameInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e.currentTarget.value);
    setLastName(e.currentTarget.value.replace(""," "));
  };
  const handleSejelInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e.currentTarget.value);
    if(e.currentTarget.value!=='')
      setSejel(parseInt(e.currentTarget.value));
  };

  const handleSearchubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    //if(SelectedBalda!==null)
    // setBaldaID(SelectedBalda?.BALDA_ID);
    //if(SelectedMazhab!==null)
    //setMazhabID(SelectedMazhab?.MAZHAB_ID);

    GetPersonsList(paginationModel.page, paginationModel.pageSize);
    //alert(SelectedBalda?.BALDA_ID);
    //alert(MazhabID);
  };

  const GetPersonsList = async (page:number, pageSize:number) => {
    /*if(firstName.trim().length===0)
      setFirstName("0");
      if(lastName.trim().length===0){
      setLastName("0");
      alert(lastName);
    }
    else{alert(lastName.length);}*/

    const result: Result_Get_Person_List | null = await GetPersons(
      BaldaID ?? 0,
      firstName,
      lastName,
      MazhabID ?? 0,
      Sejel ?? 0,
      page ,
      pageSize
    );
    console.log(result);
    
    setPageState(old=>({...old, total:result?result.total:0}));
    setPersonList(result?result.data:[]);

    
  };

  //const [rowCountState, setRowCountState] = React.useState(0);

  useEffect(() => {
    //setPageState(old=>({...old, isLoading:false}));
    const GetInitailData = async () => {
      const baldas: Balda[] | null = await GetBaldas();
      setBaldaList(baldas);

      const mazhabs: Mazhab[] = await GetMazhabs();
      setMazhabList(mazhabs);

    };

    GetInitailData();
  },[]);
  return (
    <div>
      <form onSubmit={handleSearchubmit}>
      
        <Grid container
          css={css`
            box-sizing: border-box;
            top: 5;
            width: 100%;
          
            align-items: left;
            padding: 10px 20px;
            background-color: #fff;
            border-bottom: 1px solid ${gray5};
            justify-content: space-between;
            box-shadow: 0 3px 7px 0 rgba(110, 112, 114, 0.21);
          `}
        >
          <Grid item xs={12} md={3} css={css`width:90%`} mt={2} mb={2}>
          <Autocomplete options={BaldaList}
            id="select-balda" 
            getOptionLabel={(option: Balda) => option.BALDA_NAME}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a balda"
                variant="outlined"
              />
            )}
            value={SelectedBalda}
            onChange={(_event, newBlada) => {
              setSelectedBalda(newBlada);
              if (newBlada != null) setBaldaID(newBlada.BALDA_ID);
              else setBaldaID(0);
            }}
            isOptionEqualToValue={(option, value) =>
              value === undefined || option.BALDA_ID === value.BALDA_ID
            }
          />
          </Grid>
          <Grid item xs={12} md={1} css={css`width:90%`} mt={2} mb={2}>
          <FormControl  css={css`width: 125;`} >
            <InputLabel htmlFor="name">Name</InputLabel>
            <Input
              id="firstname" value={firstName}
              type="text"
              onChange={handleFirstNameInputChange}
            />
          </FormControl>
          </Grid>

          <Grid item xs={12} md={2} css={css`width:90%`} mt={2} mb={2}>
          <FormControl  >
            <InputLabel htmlFor="familyname">Family Name</InputLabel>
            <Input
              id="familyname"
              type="text" value={lastName}
              onChange={handleLastNameInputChange}
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} md={1} css={css`width:90%`} mt={2} mb={2}>
          <FormControl>
            <InputLabel htmlFor="sejel">Sejel</InputLabel>
            <Input
              id="sejel"
              type="number"
              value={Sejel}
              onChange={handleSejelInputChange}
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} md={2} mt={2} mb={2}>
          <Autocomplete
            options={MazhabList}
            id="select-mazhab"
            
            getOptionLabel={(option: Mazhab) => option.MAZHAB_DESC}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a mazhab"
                variant="outlined"
              />
            )}
            value={SelectedMazhab}
            onChange={(_event, newMazhab) => {
              setSelectedMazhab(newMazhab);

              if (newMazhab != null) setMazhabID(newMazhab.MAZHAB_ID);
              else setMazhabID(0);
            }}
            isOptionEqualToValue={(option, value) =>
              value === undefined || option.MAZHAB_ID === value.MAZHAB_ID
            }
          />
          </Grid>
          <Grid item xs={12} md={1} css={css`width:90%`} mt={2} mb={2}>
          <Button css={css`width:90%`} 
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            
          >
            Search
          </Button>
          </Grid>
        </Grid>
      </form>
      <Container
        css={css`
          height: 70;
          width: '100%';
          marginTop:"25";
          marginBottom:"25";
        `}
      >
        <DataGrid
        
          rowCount={pageState.total}
          pageSizeOptions={[5,25,100]}
          rows={PersonList}
          columns={Columns}
          pagination={true}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model:GridPaginationModel,details:GridCallbackDetails<any>)=>
            {              
              setPaginationModel({page:model.page,pageSize:model.pageSize});

              GetPersonsList(model.page, model.pageSize);
            }}
    
          getRowId={(row: any) => row.PERSON_ID}
          initialState={{
            sorting:{sortModel:[{field:'CHOHRA',sort:'asc'}]}
          }}
          components={{Toolbar: ()=>{
return <GridToolbarContainer css={css` justify-content: flex-end;`}>
<GridToolbarExport></GridToolbarExport>
</GridToolbarContainer>
          }         
          }}
        />
      </Container>
    </div>
  );
};
