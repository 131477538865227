import React,{lazy,Suspense} from 'react';
import logo from './logo.svg';

/** @jsx jsx */ /** @jsxRuntime classic */
import { css,jsx } from '@emotion/react'
import { fontFamily, fontSize, gray2 } from './Styles';

import { BrowserRouter,Routes, Route,Navigate } from 'react-router-dom';




import { Criteria } from './Criteria';



const App: React.FC = () => {
  return (
    <BrowserRouter>
  <div 
  css={css`font-family: ${fontFamily};font-size: ${fontSize};color: ${gray2};`}
  >
  
  <Routes>
  
 
  
  
  <Route path="/" element={<Criteria/>} />
  
  </Routes>
  
  </div>
  </BrowserRouter>
  );
  };

export default App;
